<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="800"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Category</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-650px scroll-y mx-5 mx-xl-15 py-7"
        >
          <!--begin::Body-->
          <form id="editForm" class="pa-3 pb-0">
            <v-stepper v-model="e1">
              <v-stepper-header>
                <template v-for="n in steps">
                  <v-stepper-step
                    :key="`${n}-step`"
                    :complete="e1 > n"
                    :step="n"
                  >
                    <span v-if="n === 1">Edit Title</span>
                    <span v-if="n === 2">Edit Description</span>
                    <span v-if="n === 3">Settings</span>
                  </v-stepper-step>

                  <v-divider v-if="n !== steps" :key="n"></v-divider>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content :step="1" :key="`1-content`">
                  <div
                    class="justify-content-between stepper-container flex-column d-flex"
                    v-if="formData"
                  >
                    <div class="stepper-content">
                      <v-text-field
                        v-for="(item, index) in languages"
                        :key="'title' + index"
                        v-model="formData['title_' + item.index]"
                        :counter="40"
                        :label="'Title(' + item.text + ')'"
                        required
                        dense
                        outlined
                        clearable
                      ></v-text-field>
                    </div>
                    <div class="stepper-footer">
                      <div></div>
                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(1)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="2" :key="`2-content`">
                  <div
                    v-if="formData"
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-content">
                      <v-text-field
                        v-for="(item, index) in languages"
                        :key="'description' + index"
                        v-model="formData['description_' + item.index]"
                        :counter="40"
                        :label="'Description(' + item.text + ')'"
                        required
                        dense
                        outlined
                        clearable
                      ></v-text-field>
                    </div>

                    <div class="stepper-footer">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(2)"
                      >
                        Previous
                      </button>

                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(2)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="3" :key="`3-content`">
                  <div
                    v-if="formData"
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-content">
                      <v-file-input
                        v-if="formData"
                        v-model="formData.image"
                        show-size
                        counter
                        multiple
                        dense
                        outlined
                        accept="image/png, image/jpeg, image/bmp"
                        prepend-icon="mdi-camera"
                        label="Image"
                        clearable
                      ></v-file-input>
                      <v-switch
                        v-model="formData.home_page"
                        color="primary"
                        label="Home Page"
                      ></v-switch>
                    </div>
                    <div class="stepper-footer">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(3)"
                      >
                        Previous
                      </button>

                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="submitEditForm"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </form>

          <!--end::Body-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "EditCategory",
  props: ["refresher", "loader", "customers"],
  data: () => ({
    dialog: false,
    rtlFlag: false,
    formData: null,
    tabIndex: 0,
    e1: 1,
    steps: 3,
  }),
  computed: {
    itemForAction: function () {
      return this.$store.getters.getItemForAction;
    },
    languages: function () {
      if (this.itemForAction) return this.itemForAction.store_group.languages;
      return null;
    },
  },
  methods: {
    convertToFormData() {
      let data = new FormData();
      data.append("category_id", this.itemForAction.id);
      for (let key in this.formData) {
        if (key === "image") {
          if (this.formData[key]) data.append(key, this.formData[key][0]);
        } else if (key === "home_page") {
          if (this.formData[key]) data.append(key, 1);
          else data.append(key, 0);
        } else if (key === "parent_id") {
          if (this.formData[key]) data.append(key, this.formData[key]);
        } else {
          if (this.formData[key]) data.append(key, this.formData[key]);
        }
      }
      return data;
    },
    errorMessage(message) {
      Swal.fire({
        title: "Error",
        text: message,
        icon: "error",
        showConfirmButton: false,
        timer: 2500,
      });
    },
    submitEditForm() {
      let data = this.convertToFormData();
      if (!data) {
        this.errorMessage("You should input all data", "error", "Error");
        return;
      }
      this.loader(true);
      if (!data) {
        Swal.fire({
          title: "Error",
          text: `You should input all data`,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      ApiService.post("/stores/portal/categories/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `The Category data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.loader(false);
        });
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        if (n == 1) {
          var test = true;
          this.languages.map((item) => {
            if (
              !this.formData["title_" + item.index] ||
              this.formData["title_" + item.index] == ""
            ) {
              test = false;
              return;
            }
          });
          if (test) this.e1 = n + 1;
          else
            this.errorMessage("Should Complete Title data in all languages!");
        } else this.e1 = n + 1;
      }
    },
    previewStep(n) {
      if (n === 1) this.el = this.steps;
      else this.e1 = n - 1;
    },
    resetEditForm() {
      let formData = {
        image: null,
        parent_id: this.itemForAction.parent.id
          ? this.itemForAction.parent.id
          : null,
        home_page: this.itemForAction.home_page
          ? this.itemForAction.home_page
          : false,
      };
      this.languages.map((item) => {
        formData["title_" + item.index] = this.itemForAction.title[item.index]
          ? this.itemForAction.title[item.index]
          : "";
        formData["description_" + item.index] = this.itemForAction.description[
          item.index
        ]
          ? this.itemForAction.description[item.index]
          : "";
      });
      this.formData = formData;
      this.nextStep(3);
    },
    toggleModal() {
      if (this.dialog) this.resetEditForm();
      this.tabIndex = 0;
      this.dialog = !this.dialog;
    },
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
};
</script>
