<template>
  <div class="p-4 bg-white rounded-lg">
    <DataTable />
  </div>
</template>

<script>
import DataTable from "@/own/components/stores/categories/DataTable.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "Categories",
  components: { DataTable },

  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
};
</script>
