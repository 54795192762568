<template>
  <div v-if="items">
    <div v-if="$slots.top">
      <slot name="top"></slot>
    </div>
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      show-select
      :items="items"
      :headers="headers"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortOrder"
      :items-per-page="itemPerPage"
      item-key="id"
      class="m-2 font-weight-bold cursor"
      hide-default-footer
      mobile-breakpoint="40"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE!</template>
      <template v-slot:no-results> NO RESULTS HERE!</template>
      <!--    no-data end-->
      <!--    table top-->
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 d-flex justify-space-between align-center flex-wrap"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            :table_properties="table_properties"
            v-if="$vuetify.breakpoint.smAndUp"
          ></datatable-detail>
          <div
            v-else
            class="d-flex align-center justify-space-between mb-3 w-100"
          >
            <datatable-detail
              :table_properties="table_properties"
            ></datatable-detail>
            <b-button
              v-show="$vuetify.breakpoint.xsOnly"
              depressed
              small
              variant="outline-primary"
              class="px-5 py-3 mb-1 btn--tools"
              @click="toolBarVisionState = !toolBarVisionState"
            >
              TOOLS
            </b-button>
          </div>
          <div
            v-show="isToolbarVisiable"
            style="display: flex; justify-content: center; align-items: center"
            class="card-toolbar ma-0 pa-0 col-md-8 col-sm-10 col-12 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
          >
            <div class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0" v-if="table_parent">
              <button
                depressed
                small
                class="btn btn--export-filter"
                :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
                @click="goBack(table_parent)"
              >
                Back
              </button>
            </div>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('create')"
            >
              <button
                @click.prevent="showAddItemModal"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-plus-box-outline</v-icon>
                </span>
                Add {{ table_properties.title }}
              </button>
            </div>

            <add-category
              :loader="pageLoad"
              :refresher="updateTableContent"
              :stores="stores"
              :categories="categories"
              ref="addItem"
            ></add-category>
            <!--begin::Button-->

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('export')"
            >
              <button
                @click="$refs.exportSidebar.$data.exportSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-export-variant</v-icon>
                </span>
                Export
              </button>
            </div>

            <!--end::Button-->

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="filters.length > 0"
            >
              <button
                @click="$refs.filterSidebar.$data.filterSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                Filter
              </button>
            </div>
          </div>
        </div>
        <!-- Export Sidebar start -->
        <ExportSidebar
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->

        <!-- Filter Sidebar start -->
        <FilterSidebar
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->

        <!-- filter container end -->
      </template>

      <template
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>

      <!-- top end -->
      <!-- column start -->
      <template #item.id="{ item, header, value }">
        <span :class="header.itemClass">
          {{ value }}
        </span>
      </template>
      <template #item.store_group="{ item, header, value }">
        <store-item :header="header" :store="value"></store-item>
      </template>
      <template #item.parent="{ item, header, value }">
        <span :class="header.itemClass">
          {{ value.title }}
        </span>
      </template>
      <template #item.title="{ item, header, value }">
        <show-simple-content
          :itemForAction="value"
          :itemClass="header.itemClass"
          :item="item"
        ></show-simple-content>
      </template>
      <template #item.image="{ item, header, value }">
        <ShowUrlImage :url="value"></ShowUrlImage>
      </template>

      <template #item.home_page="{ value }">
        <StatusColumn
          class="d-flex justify-center align-content-start w-70px"
          :value="value"
        ></StatusColumn>
      </template>
      <template #item.description="{ item, header, value }">
        <show-simple-content
          :itemForAction="value"
          :itemClass="header.itemClass"
          :item="item"
        ></show-simple-content>
      </template>
      <template #item.description="{ item, header, value }">
        <show-simple-content
          :itemForAction="value"
          :itemClass="header.itemClass"
          :item="item"
        ></show-simple-content>
      </template>
      <template #item.created_at="{ item, header, value }">
        <div :class="header.itemClass">
          {{ new Date(value * 1000).toLocaleDateString() }}
        </div>
      </template>
      <template #item.updated_at="{ item, header, value }">
        <div :class="header.itemClass">
          {{ new Date(value * 1000).toLocaleDateString() }}
        </div>
      </template>
      <template #item.sub_categories="{ item, header, value }">
        <span :class="header.itemClass">
          <a
            href="#"
            class="btn btn-light-facebook btn-sm px-4 custom-btn text-no-wrap"
            @click="showSubCategores(item)"
            >View</a
          >
        </span>
      </template>

      <template #item.action="{ item, header, value }">
        <DataTableActionSelector
          :item="item"
          :header="header"
          :value="value"
          :actions="actions"
          :handle_function_call="handle_function_call"
        ></DataTableActionSelector>
        <EditCategory
          :itemForAction="item"
          :refresher="updateTableContent"
          :loader="pageLoad"
          :ref="`editModal_${item.id}`"
        ></EditCategory>
      </template>
      <!-- column end -->
    </v-data-table>
    <Pagination
      :setitemPerPage="setitemPerPage"
      :getitemPerPage="getitemPerPage"
      :getpageNumber="getpageNumber"
      :setpageNumber="setpageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
  </div>
</template>

<script>
import {
  UPDATE_TABLE_STRUCTURE,
  UPDATE_TABLE_DATA,
  UPDATE_CREATE_DATA,
  SET_TABLE_SORTBY,
  SET_TABLE_SORTORDER,
  SET_TABLE_FILTER,
  SET_TABLE_PERPAGE,
  SET_TABLE_PAGE,
  EXPORT_TABLE_DATA,
} from "@/core/services/store/categories.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

import EditCategory from "@/own/components/stores/categories/EditCategory.vue";
import AddCategory from "@/own/components/stores/categories/AddCategory.vue";
import ShowUrlImage from "@/own/components/datatable/ShowUrlImage.vue";
import ShowSimpleContent from "@/own/components/datatable/ShowSimpleContent.vue";
import StoreItem from "@/own/components/datatable/StoreItem.vue";
import Pagination from "@/own/components/pagination/Pagination.vue";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import DataTableActionSelector from "@/own/components/DataTableActionSelector.vue";
import StatusColumn from "@/own/components/datatable/StatusColumn";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar";

export default {
  name: "DataTable",
  components: {
    DatatableDetail,
    Pagination,
    EditCategory,
    ShowUrlImage,
    StoreItem,
    AddCategory,
    ShowSimpleContent,
    FilterSidebar,
    ExportSidebar,
    DataTableActionSelector,
    StatusColumn,
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    allSelected: false,
    offset: true,
    parent_id: -1,
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {}).then(() => {});
    this.$store.dispatch(UPDATE_CREATE_DATA, {}).then(() => {});
    this.$store.dispatch(UPDATE_TABLE_DATA, this.parent_id, {}).then(() => {});
  },
  beforeCreate() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
  computed: {
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : this.toolBarVisionState;
    },
    actions: function () {
      return [
        {
          title: "Edit",
          method: "editItem",
          isVisible: this.permissionChecker("update"),
        },
        {
          title: "Remove",
          method: "removeItem",
          isVisible: this.permissionChecker("destroy"),
        },
      ];
    },
    //pagination
    getitemPerPage: function () {
      return this.$store.getters.getCATEGORIESTableState.per_page;
    },
    getpageNumber: function () {
      return this.$store.getters.getCATEGORIESTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getCATEGORIESTableData.total;
    },
    pageCount: function () {
      return this.$store.getters.getCATEGORIESTableData.last_page;
    },
    //filters
    filters: function () {
      return this.$store.getters.getCATEGORIESTableFilters;
    },
    itemPerPage: function () {
      return this.$store.getters.getCATEGORIESTableState.per_page;
    },
    exportHeaders: function () {
      try {
        return this.$store.getters.getCATEGORIESTablePermissions.filter(
          (col) => col.name === "export"
        )[0].columns;
      } catch (error) {
        return null;
      }
    },
    orderBy: {
      get: function () {
        return this.$store.getters.getAPPSTableState.sortBy;
      },
      set: function (newValue) {
        if (newValue) {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.parent_id,
            this.$store.getters.getAPPSTableState
          );
        } else {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.parent_id,
            this.$store.getters.getAPPSTableState
          );
        }
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        if (this.$store.getters.getAPPSTableState.sortOrder === "asc") {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        if (newValue === true) {
          this.$store.commit(SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(SET_TABLE_SORTORDER, "asc");
        }
        this.$store.dispatch(
          UPDATE_TABLE_DATA,
          this.parent_id,
          this.$store.getters.getAPPSTableState
        );
      },
    },
    headers: function () {
      let headers = false;
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.$store.getters.getCATEGORIESTableHeaders) {
          headers = this.$store.getters.getCATEGORIESTableHeaders.filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = this.$store.getters.getCATEGORIESTableHeaders.filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        }
      } else {
        if (this.$store.getters.getCATEGORIESTableHeaders) {
          headers = this.$store.getters.getCATEGORIESTableHeaders.filter(
            (col) => col.visible === true
          );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = this.$store.getters.getCATEGORIESTableHeaders.filter(
            (col) => col.visible === true
          );
        }
      }
      let supHeader = [...headers];
      const actions = supHeader.pop();
      supHeader.push({
        value: "sub_categories",
        text: "show SubCategories",
        type: "text",
        sortable: false,
        exportable: false,
        align: "left",
        itemClass: "second-text",
        width: "100px",
        class:
          "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls",
      });
      supHeader.push(actions);
      supHeader.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        header.width = undefined;
        if (header.value === "action") {
          header.class =
            "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return supHeader;
    },
    items: function () {
      if (this.$store.getters.getCATEGORIESTableData) {
        return this.$store.getters.getCATEGORIESTableData.data;
      }
      return undefined;
    },
    table_permissions: function () {
      if (this.$store.getters.getCATEGORIESTablePermissions) {
        return this.$store.getters.getCATEGORIESTablePermissions.map(function (
          perm
        ) {
          return perm.name;
        });
      } else {
        return false;
      }
    },
    stores: function () {
      if (this.$store.getters.getCATEGORIESCreateData) {
        return this.$store.getters.getCATEGORIESCreateData.stores;
      }
      return undefined;
    },
    categories: function () {
      if (this.$store.getters.getCATEGORIESCreateData) {
        return this.$store.getters.getCATEGORIESCreateData.categories;
      }
      return undefined;
    },
    table_properties: function () {
      if (this.$store.getters.getCATEGORIESTableProperties)
        return this.$store.getters.getCATEGORIESTableProperties;
      else return null;
    },
    table_parent: function () {
      if (this.$store.getters.getCATEGORIESTableData) {
        return this.$store.getters.getCATEGORIESTableData.parent;
      }
      return undefined;
    },
  },
  methods: {
    // pagination
    setitemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setpageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    // filters
    setTableFiltersValues(val) {
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = { ...this.$store.getters.getCATEGORIESTableState };
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, this.parent_id, state)
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch((res) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          Swal.fire({
            title: "",
            text: `${res}`,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store.commit(SET_TABLE_FILTER, []);
      this.$store
        .dispatch(
          UPDATE_TABLE_DATA,
          this.$store.getters.getCATEGORIESTableState
        )
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    // filters end
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    goBack(parent) {
      if (parent.parent_id) {
        this.parent_id = parent.parent_id;
        this.$store
          .dispatch(UPDATE_TABLE_DATA, this.parent_id, {})
          .then(() => {});
      } else {
        this.parent_id = -1;
        this.$store
          .dispatch(UPDATE_TABLE_DATA, this.parent_id, {})
          .then(() => {});
      }
    },
    showSubCategores(item) {
      this.parent_id = item.id;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, this.parent_id, {})
        .then(() => {});
    },
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    editItem(item) {
      this.$refs[`editModal_${item.id}`].toggleModal();
    },
    removeItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          const data = {
            id: item.id,
          };
          ApiService.post("/stores/portal/categories/destroy", data)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "The Category has been deleted.",
                "success"
              );
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    async updateTableContent() {
      this.pageLoad(true);
      await this.$store
        .dispatch(
          UPDATE_TABLE_DATA,
          this.parent_id,
          this.$store.getters.getCATEGORIESTableState
        )
        .then(() => {});
      this.$forceUpdate();
      this.pageLoad(false);
    },
    showAddItemModal() {
      this.$refs.addItem.toggleModal();
    },
    //---------------------export-------------------------
    downloadItem(columns) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.export_modal = false;
      this.$store
        .dispatch(EXPORT_TABLE_DATA, {
          columns: columns,
          filters: this.$store.getters.getCATEGORIESTableState.filters,
        })
        .then(() => {
          const link = document.createElement("a");
          link.href = this.$store.getters.getCATEGORIESExportUrl;
          link.download = "ProblemWithExport.csv";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
          this.$refs.exportSidebar.$refs.export.toggleModal();
        });
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getitemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getitemPerPage * this.getpageNumber - this.getitemPerPage;
          innerItems = this.items.slice(startFrom, this.getitemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
  },
};
</script>
