<template>
  <v-tooltip right v-if="itemForAction">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" :class="itemClass">
        {{
          itemForAction[languages[0].index]
            ? itemForAction[languages[0].index]
            : ""
        }}
      </span>
    </template>
    <div>
      <div v-for="(item, index) in languages" :key="item.index + index">
        <span v-if="itemForAction[item.index]">
          {{ item.text }} :{{ itemForAction[item.index] }}
        </span>
      </div>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: "ShowSimpleContent",
  props: ["itemForAction", "item", "itemClass"],
  computed: {
    languages: function () {
      return this.item.store_group.languages;
    },
  },
};
</script>
